import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  // Save data to localStorage
  setItem(key: string, value: any): void {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  // Get data from localStorage
  getItem<T>(key: string): T | null {
    const stringValue = localStorage.getItem(key);
    return stringValue ? JSON.parse(stringValue) : null;
  }

  // Remove data from localStorage
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all data from localStorage
  clear(): void {
    localStorage.clear();
  }

  // Check if a key exists in localStorage
  hasItem(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  // Get all keys in localStorage
  getKeys(): string[] {
    return Object.keys(localStorage);
  }
}
